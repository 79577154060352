<template>
  <div class="site-list">
    <SearchBox />
  </div>
</template>

<style>
.site-list {
  width: 100%;
  height: 100%;
  background: #eeeeee;
}
</style>

<script>
import SearchBox from '../components/SearchBox'

export default {
  data () {
    return {
    }
  },
  components: {
    SearchBox
  },
  computed: {
  },
  methods: {
  }
}
</script>
