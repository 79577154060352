<template>
  <div class="admin-container">
    <div v-if="adminLevel > 0" class="admin-info">
      <span>{{adminLevel > 1 ? "超级管理员" : "管理员"}}：{{adminName}}</span>
    </div>
    <div v-if="adminLevel > 0" class="admin--list-header">
      <span class="emphasis-text">{{date}}{{slotName}}</span>预约列表（共{{reserveCount}}次预约）
    </div>
    <div v-if="adminLevel > 0" class="reserve-record-list admin-reserve-record-list">
      <div class="reserve-record-empty" v-if="loading">加载中，请稍候...</div>
      <div class="reserve-record-empty" v-else-if="records.length===0">未找到{{date}}{{slotName}}的预约记录~</div>
      <div class="reserve-record-item" v-for="(item, i) in records" :key="i">
        <div>{{item.reservationDate}} {{item.reservationSlotName}}</div>
        <div>{{item.site.name}}</div>
        <div>{{item.touristCount}}人</div>
        <div class="reserve-qr-icon" @click="gotoReserveDetail(item)">游客详情</div>
      </div>
    </div>
    <div v-if="adminLevel > 0" class="bottom-button-bar">
      <div class="button-container">
        <div class="confirm-button" @click="goLastPage">返回</div>
      </div>
    </div>

    <div v-if="adminLevel === 0" class="un-authorized-container">
      <img src="../../assets/images/un-authorized.png" />
      <div class="emphasis-text">无权限查看管理页面</div>
      <div>请先点击下方按钮申请成为管理员之后，<br/>再联系负责人授权</div>
    </div>
    <div v-if="adminLevel === 0" class="bottom-button-bar">
      <div class="button-container">
        <div class="confirm-button" @click="applySiteManager">申请成为管理员</div>
      </div>
    </div>
  </div>
</template>

<style>
.qr-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>

<script>
import { ElMessage } from 'element-plus'
import { checkAdmin, reservationList } from '../../api/Admin'
import '../../components/ReserveRecord.css'
import './Admin.css'

export default {
  data () {
    return {
      siteId: 0,
      slotId: 0,
      slotName: '',
      date: '',
      adminLevel: 0,
      reserveCount: 0,
      records: [],
      slot: null
    }
  },
  components: {
  },
  computed: {
    adminName () {
      const userName = localStorage.getItem('name')
      return userName
    }
  },
  methods: {
    checkAdmin () {
      checkAdmin(this.siteId).then(res => {
        const data = res.data
        console.log(data)
        this.adminLevel = data
        this.initData()
      }, res => {
        console.log(res)
        this.adminLevel = 0
      })
    },
    listRecords () {
      this.loading = true
      reservationList(this.siteId, this.slotId, this.date, 1, 10).then(res => {
        const data = res.data
        console.log(data)
        this.reserveCount = data.count
        this.records = data.list.map(item => {
          return this.convertReservation(item)
        })
        this.loading = false
      }, res => {
        console.log(res)
        this.loading = false
      })
    },
    convertReservation (item) {
      return {
        id: item.id,
        site: {
          id: item.siteId,
          name: item.siteName
        },
        reservationDate: item.reservationDate,
        reservationSlotName: item.reservationSlotName,
        touristCount: item.touristCount
      }
    },
    initData () {
      this.listRecords()
    },
    applySiteManager () {
      this.$router.push('/admin/apply')
    },
    goLastPage () {
      this.$router.go(-1)
    },
    gotoReserveDetail (item) {
      this.$router.push('/admin/reserveDetail?reserve=' + item.id)
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('site id is:' + query.site)
    const siteId = query.site ? query.site : 1
    const slotId = query.slot ? query.slot : 0
    const slotName = query.slotName ? query.slotName : ''
    const date = query.date ? query.date : '2022-01-25'
    this.siteId = siteId
    this.slotId = slotId
    this.slotName = slotName
    this.date = date
    document.title = '预约记录'
    this.checkAdmin()
  }
}
</script>
