<template>
  <div class="admin-container">
    <div class="block-button-container">
      <div class="block-button-row">
        <div class="block-button" @click="prepareScan">
          <img src="../../assets/images/verification.png" />
          扫码
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.qr-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>

<script>
import { ElMessage } from 'element-plus'
import './Admin.css'

export default {
  data () {
    return {
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    prepareScan () {
      const state = {
      }
      const stateParam = encodeURIComponent(JSON.stringify(state))
      window.location.href = 'https://user.pecaca.com/wxjs/index.html?appId=2&apiId=1&state=' + stateParam
    }
  },
  mounted () {
    document.title = '设备扫码'
  }
}
</script>
