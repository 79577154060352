<template>
  <div class="qr-container">
    <DigitClock />
    <ReserveDetail :reservation="reservation" />
    <ReserveTourist :tourists="tourists" />
    <div class="bottom-button-bar">
      <div class="button-container">
        <div class="normal-button" @click="goLastPage">返回预约记录</div>
      </div>
      <div class="button-container">
        <div class="confirm-button" @click="cancelReservation">取消预约</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import DigitClock from '../components/DigitClock'
import ReserveDetail from '../components/ReserveDetail'
import ReserveTourist from '../components/ReserveTourist'
import { reservationDetail, cancelReservation } from '../api/Reservation'
import './Qr.css'

export default {
  data () {
    return {
      reserveId: 0,
      reservation: null,
      tourists: []
    }
  },
  components: {
    DigitClock,
    ReserveDetail,
    ReserveTourist
  },
  computed: {
  },
  methods: {
    cancelReservation () {
      const r = this.reservation
      this.$confirm('确定要取消‘' + r.siteName + r.reservationDate + r.reservationSlotName + '’的预约吗?', '取消预约确认', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(() => {
        this.doCancelReservation(r)
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: 'Delete canceled'
        // })
      })
    },
    goLastPage () {
      this.$router.go(-1)
    },
    getReservationDetail () {
      if (!this.reserveId) {
        return
      }
      reservationDetail(this.reserveId).then(res => {
        const data = res.data
        console.log(data)
        this.reservation = data.reservation
        this.tourists = data.tourists
      }, res => {
        console.log(res)
        ElMessage({
          message: res,
          offset: this.offset,
          type: 'error'
        })
      })
    },
    doCancelReservation (r) {
      cancelReservation(r).then(res => {
        const data = res.data
        console.log(data)
        const msg = '取消预约‘' + r.siteName + r.reservationDate + r.reservationSlotName + '’成功'
        this.$confirm(msg, '取消成功', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'success'
        }).then(() => {
          this.goLastPage()
        }).catch((err) => {
          console.log(err)
          this.goLastPage()
        })
      }, res => {
        console.log(res)
        ElMessage({
          message: res,
          offset: this.offset,
          type: 'error'
        })
      })
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('reserve id is:' + query.reserve)
    const reserveId = query.reserve ? query.reserve : 0
    this.reserveId = reserveId
    this.getReservationDetail()
  }
}
</script>
