<template>
  <div class="site-info" :style="{ backgroundImage: 'url(' + site.avatar + ')' }">
    <div v-if="showSiteName" class="site-title">
      <img class="title-icon" src="../assets/images/temple-white.png" />
      <span class="title-text">{{site.name}}</span>
    </div>
    <div v-if="showSiteTime" class="site-time">
      <img class="time-icon" src="../assets/images/clock-white.png" />
      <span class="time-text">开放时间：{{site.time}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    site: Object
  },
  data () {
    return {
      showSiteName: false,
      showSiteTime: false
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style>
.site-info {
  box-sizing: border-box;
  height: 230px;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #ccc;
  background-image: url('../assets/images/book_small3.jpg');
  background-size: 100% 100%;
}
.site-title, .site-time {
  display: flex;
  align-items: center;
  margin: 5px;
  font-size: 24px;
  font-weight: bold;
  color: white;
}
.site-time {
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
}
.title-text, .time-text {
  margin-left: 10px;
}
.title-icon, .time-icon {
  width: 20px;
  height: 20px;
}
.site-notice {
  margin: 5px;
  color: #787878;
  text-align: left;
}
</style>
