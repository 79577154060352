import request from '../utils/request'

export const checkName = name => {
  return request({
    url: '/oversea/student/check',
    method: 'post',
    data: {
      name: name
    }
  })
}
