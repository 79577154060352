<template>
  <div class="reserve-tourist">
    <div class="tourist-card" v-for="(item, i) in computedTourists" :key="i">
      <div class="tourist-info-row">
        <div class="info-name">姓名</div>
        <div class="info-value">{{item.name}}</div>
      </div>
      <div class="tourist-info-row">
        <div class="info-name">证件号</div>
        <div class="info-value">{{item.idNo}}</div>
      </div>
      <div class="tourist-id-type">
        {{item.idType.name}}
      </div>
    </div>
  </div>
</template>

<script>
import './ReserveTourist.css'
export default {
  props: {
    tourists: Array
  },
  data () {
    return {
      idTypeList: [
        {
          id: 1,
          name: '身份证'
        },
        {
          id: 2,
          name: '护照'
        }
      ]
    }
  },
  components: {
  },
  computed: {
    computedTourists () {
      return this.tourists.map(item => {
        return this.convertTourist(item)
      })
    }
  },
  methods: {
    convertTourist (item) {
      return {
        id: item.id,
        uuid: item.uuid,
        name: item.name,
        idType: this.convertIdType(item.idType),
        idNo: item.idNo,
        mobile: item.mobile
      }
    },
    convertIdType (idType) {
      for (var i = 0; i < this.idTypeList.length; i++) {
        const item = this.idTypeList[i]
        if (item.id === idType) {
          return item
        }
      }
      return idType
    }
  }
}
</script>
