<template>
  <div class="make-reserve-container">
    <div class="reserve-date-picker">
      <div class="pick-result" @click="pickDate">
        <span class="result-header">选择日期</span>
        <span class="result-dropdown">{{dateResult}}</span>
        <img class="drop-down-icon" src="../assets/images/drop-down-gray.png" />
      </div>
    </div>
    <div class="reserve-tourist-picker">
      <div class="tourist-result" @click="addTourist">
        <span class="result-header">个人信息</span>
        <span class="result-dropdown">最多{{ site.maxCountPerReserve }}人</span>
        <img class="drop-down-icon" src="../assets/images/adding.png" />
      </div>
      <div class="reserve-record-empty" v-if="touristLoading">加载中，请稍候...</div>
      <div class="tourist-info" v-for="(item, i) in touristList" :key="i">
        <div class="tourist-name-mobile" @click="toggleTouristCheck(item)">
          <div :class="touristCheckboxClass(item)" />
          <span>{{item.name}}</span><span>{{item.mobile}}</span>
        </div>
        <div class="tourist-op">
          <div class="edit-icon" @click="editTourist(item)"></div>
          <div class="delete-icon" @click="deleteTourist(item)"></div>
        </div>
      </div>
    </div>
    <div class="reserve-notice" @click="toggleReserveNotice"><div :class="noticeRead ? 'checkbox-checked':'checkbox-uncheck'" />
      <span>点击阅读预约须知</span>
    </div>
    <div class="my-reservation" @click="goToMyReservation">
      <div>我的预约</div>
    </div>
    <div class="bottom-button-bar">
      <div class="button-container">
        <div class="normal-button" @click="addTourist">添加人员</div>
      </div>
      <div class="button-container">
        <div class="confirm-button" @click="makeReservation">立即预约</div>
      </div>
    </div>

    <el-dialog title="预约须知" v-model="noticeDialogVisible" width="80%">
      <div class="notice-text">
          <p v-for="(item, idx) in noticeList" :key="idx">{{ item }}</p>
      </div>
      <template #footer>
        <div class="dialog-footer" @click="noticeDialogVisible=false">我知道了</div>
      </template>
    </el-dialog>
    <BottomUpFloatLayer
      :visible="datePickVisible"
      :handleClose="handleDatePickClose"
      :title="datePickTitle"
    >
      <template #contents>
        <div class="date-pick-layer">
          <div class="date-pick-container">
            <div class="date-list">
              <div class="date-list-item" v-for="(item, i) in dateList" :key="i" @click="dateSelect(i)">
                <div :class="dateListItemClass(item)">
                  <span>{{item.date}}</span><span>周{{item.day}}</span>
                </div>
              </div>
            </div>
            <div class="time-slot-list" v-if="slotList.length > 0">
              <div class="slot-list-item" v-for="(item, i) in slotList" :key="i" @click="slotSelect(i)">
                <div :class="slotListItemClass(item)">
                  <div class="slot-main">
                    <span class="slot-name">{{item.name}}</span>
                    <span>剩余<span class="slot-quota">{{item.quota}}</span>名</span>
                  </div>
                  <div class="slot-time">
                    <span>开放时间：{{item.startTime}} - {{item.endTime}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-container">
            <div class="confirm-button" @click="confirmSelect">确定</div>
          </div>
        </div>
      </template>
    </BottomUpFloatLayer>

    <BottomUpFloatLayer
      :visible="touristAddVisible"
      :handleClose="handleTouristAddClose"
      :title="touristAddTitle"
    >
      <template #contents>
        <div class="tourist-add-layer">
          <div class="tourist-add-container">
            <div class="text-input">
              <div class="text-input-header">姓名</div>
              <div class="text-input-input">
                <el-input placeholder="请输入真实姓名" v-model="inputTouristName"></el-input>
                <img class="input-close-icon" v-if="inputTouristName"
                  src="../assets/images/close_round.png"
                  @click="()=>{inputTouristName = ''}" />
              </div>
            </div>
            <div class="select-input">
              <div class="select-input-header">证件类型</div>
              <div class="select-input-input" @click="showIdTypeSelectList">
                <div class="select-input-value">{{inputTouristIdType.name}}</div>
                <div class="select-input-arrow"></div>
              </div>
            </div>
            <el-drawer
              title="选择证件类型"
              v-model="idTypeSelectVisible"
              direction="btt"
              :before-close="handleIdTypeSelectClose">
              <div :class="idTypeListItemClass(item)" v-for="(item, i) in idTypeList" @click="idTypeSelect(i)" :key="i">{{item.name}}</div>
            </el-drawer>
            <div class="text-input">
              <div class="text-input-header">证件号</div>
              <div class="text-input-input">
                <el-input placeholder="请输入证件号" v-model="inputTouristIdNo"></el-input>
                <img class="input-close-icon" v-if="inputTouristIdNo"
                  src="../assets/images/close_round.png"
                  @click="()=>{inputTouristIdNo = ''}" />
              </div>
            </div>
            <div class="text-input">
              <div class="text-input-header">手机号</div>
              <div class="text-input-input">
                <el-input placeholder="请输入手机号" v-model="inputTouristMobile"></el-input>
                <img class="input-close-icon" v-if="inputTouristMobile"
                  src="../assets/images/close_round.png"
                  @click="()=>{inputTouristMobile = ''}" />
              </div>
            </div>
          </div>
          <div class="button-container">
            <div class="confirm-button" @click="confirmTourist">{{editingTouristId ? "修改" : "添加"}}</div>
          </div>
        </div>
      </template>
    </BottomUpFloatLayer>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
import BottomUpFloatLayer from './common/BottomUpFloatLayer'
import './ReserveDatePicker.css'
import { slotList } from '../api/Site'
import { makeReservation } from '../api/Reservation'
import { touristList, addTourist, deleteTourist, updateTourist } from '../api/Tourist'

export default {
  props: {
    site: Object
  },
  data () {
    return {
      offset: 300,
      siteId: 1,
      pickResult: {
        date: {
          date: '01-16',
          day: '日'
        },
        slot: {
          name: ''
        }
      },
      curDateIdx: 0,
      curSlotIdx: 0,
      datePickVisible: false,
      touristAddVisible: false,
      idTypeSelectVisible: false,
      noticeRead: false,
      noticeDialogVisible: false,
      touristLoading: false,
      datePickTitle: '选择预约时间',
      touristAddTitle: '添加人员',
      editingTouristId: 0,
      editingTouristUuid: null,
      inputTouristName: '',
      inputTouristMobile: '',
      inputTouristIdType: {
        id: 1,
        name: '身份证'
      },
      inputTouristIdNo: '',
      dateList: [],
      slotList: [],
      touristList: [],
      checkedTouristList: [],
      idTypeList: [
        {
          id: 1,
          name: '身份证'
        },
        {
          id: 2,
          name: '护照'
        }
      ],
      noticeList: []
    }
  },
  components: {
    BottomUpFloatLayer
  },
  computed: {
    dateResult () {
      return this.pickResult.date.date + ' 星期' + this.pickResult.date.day + ' ' + this.pickResult.slot.name
    }
  },
  methods: {
    generateNoticeList () {
      if (this.site && this.site.notice) {
        this.noticeList = this.site.notice.split('\n')
      }
    },
    generateDateList () {
      console.log(this.site)
      const startDateStr = this.site.startDate ? this.site.startDate : '2024-01-01'
      const now = new Date(startDateStr)
      const days = this.site.durationDays ? this.site.durationDays : 7
      const hour = now.getHours()
      var startDate = now
      const oneDayMils = 1000 * 3600 * 24
      if (hour >= 12) {
        startDate = new Date(now.getTime() + oneDayMils)
      }
      this.dateList = []
      for (var i = 0; i < days; i++) {
        const tmpDate = new Date(startDate.getTime() + i * oneDayMils)
        var month = tmpDate.getMonth() + 1
        var date = tmpDate.getDate()
        var day = tmpDate.getDay()
        const dateStr = (month < 10 ? ('0' + month) : month) + '-' + (date < 10 ? ('0' + date) : date)
        const fullDateStr = tmpDate.getFullYear() + '-' + dateStr
        const dayArr = ['日', '一', '二', '三', '四', '五', '六']
        this.dateList.push({
          date: dateStr,
          fullDate: fullDateStr,
          day: dayArr[day]
        })
      }
      this.dateSelect(0)
    },
    getSlotList (date) {
      console.log('site id:' + this.siteId)
      slotList(this.siteId, date).then(res => {
        const data = res.data
        console.log(data)
        this.slotList = data.list.map(item => {
          return {
            id: item.slot.id,
            name: item.slot.name,
            startTime: this.convertTime(item.slot.startTime),
            endTime: this.convertTime(item.slot.endTime),
            quota: item.quotaLeft
          }
        })
        if (this.slotList.length) {
          this.pickResult.slot = this.slotList[0]
        }
      }, res => {
        console.log(res)
      })
    },
    getTouristList () {
      this.touristLoading = true
      touristList(1, 10).then(res => {
        const data = res.data
        console.log(data)
        this.touristList = data.list.map(item => {
          return this.convertTourist(item)
        })
        this.touristLoading = false
      }, res => {
        console.log(res)
        this.touristLoading = false
      })
    },
    convertTime (time) {
      const baseHour = 8 // GMT+8
      const tmpDate = new Date(2022, 1, 22, baseHour, 0, 0)
      const ms = tmpDate.getTime()
      const newMs = ms + time
      tmpDate.setTime(newMs)
      var hour = tmpDate.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      var minutes = tmpDate.getMinutes()
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      return hour + ':' + minutes
    },
    convertTourist (item) {
      return {
        id: item.id,
        uuid: item.uuid,
        name: item.name,
        idType: this.convertIdType(item.idType),
        idNo: item.idNo,
        mobile: item.mobile
      }
    },
    convertIdType (idType) {
      for (var i = 0; i < this.idTypeList.length; i++) {
        const item = this.idTypeList[i]
        if (item.id === idType) {
          return item
        }
      }
      return idType
    },
    pickDate () {
      this.datePickVisible = true
      this.getSlotList(this.pickResult.date.fullDate)
    },
    addTourist () {
      this.editingTouristId = 0
      this.editingTouristUuid = null
      this.touristAddVisible = true
    },
    editTourist (item) {
      this.inputTouristMobile = item.mobile
      this.inputTouristName = item.name
      this.inputTouristIdType = item.idType
      this.inputTouristIdNo = item.idNo
      this.editingTouristId = item.id
      this.editingTouristUuid = item.uuid
      this.touristAddVisible = true
    },
    deleteTourist (item) {
      this.$confirm('确定要删除 ' + item.name + ' 的信息吗?', '删除人员确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const tourist = {
          id: item.id,
          uuid: item.uuid,
          name: item.name,
          idType: item.idType.id,
          idNo: item.idNo,
          mobile: item.mobile
        }
        this.doDeleteTourist(tourist)
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: 'Delete canceled'
        // })
      })
    },
    showIdTypeSelectList () {
      this.idTypeSelectVisible = true
    },
    handleDatePickClose () {
      this.datePickVisible = false
    },
    handleTouristAddClose () {
      this.touristAddVisible = false
    },
    handleIdTypeSelectClose () {
      this.idTypeSelectVisible = false
    },
    dateListItemClass (item) {
      if (item.date === this.dateList[this.curDateIdx].date) {
        return 'date-list-item-select'
      } else {
        return ''
      }
    },
    dateSelect (idx) {
      this.curDateIdx = idx
      this.pickResult.date = this.dateList[idx]
      this.getSlotList(this.pickResult.date.fullDate)
    },
    slotSelect (idx) {
      this.curSlotIdx = idx
      this.pickResult.slot = this.slotList[idx]
    },
    idTypeSelect (idx) {
      this.inputTouristIdType = this.idTypeList[idx]
      this.idTypeSelectVisible = false
    },
    slotListItemClass (item) {
      const disableClass = item.quota > 0 ? ' ' : ' slot-disable'
      if (this.slotList.length <= this.curSlotIdx) {
        this.curSlotIdx = this.slotList.length - 1
      }
      if (item.id === this.slotList[this.curSlotIdx].id) {
        return 'slot-list-item-select' + disableClass
      } else {
        return 'slot-list-item-unselect' + disableClass
      }
    },
    idTypeListItemClass (item) {
      if (item.id === this.inputTouristIdType.id) {
        return 'id-type-list-item-select'
      } else {
        return 'id-type-list-item'
      }
    },
    touristCheckboxClass (item) {
      for (var i = 0; i < this.checkedTouristList.length; i++) {
        if (item.id === this.checkedTouristList[i].id) {
          return 'checkbox-checked'
        }
      }
      return 'checkbox-uncheck'
    },
    toggleTouristCheck (item) {
      for (var i = 0; i < this.checkedTouristList.length; i++) {
        if (item.id === this.checkedTouristList[i].id) {
          this.checkedTouristList.splice(i, 1)
          return
        }
      }
      if (this.checkedTouristList && this.checkedTouristList.length >= this.site.maxCountPerReserve) {
        const msg = '人员数量最多' + this.site.maxCountPerReserve + '人'
        ElMessage({
          message: msg,
          offset: this.offset,
          type: 'error'
        })
        return
      }
      this.checkedTouristList.push(item)
    },
    toggleReserveNotice () {
      this.noticeRead = !this.noticeRead
      if (this.noticeRead) {
        this.noticeDialogVisible = true
      }
    },
    confirmSelect () {
      if (this.slotList[this.curSlotIdx].quota === 0) {
        const msg = this.dateList[this.curDateIdx].date + this.slotList[this.curSlotIdx].name + '剩余名额不足'
        ElMessage({
          message: msg,
          offset: this.offset,
          type: 'error'
        })
        return
      }
      this.pickResult.date = this.dateList[this.curDateIdx]
      this.pickResult.slot = this.slotList[this.curSlotIdx]
      this.datePickVisible = false
    },
    confirmTourist () {
      var errMsg = ''
      const idNoReg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      const mobileReg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!this.inputTouristName) {
        errMsg = '请输入人员姓名'
      } else if (!this.inputTouristIdNo) {
        errMsg = '请输入人员证件号'
      } else if (!this.inputTouristMobile) {
        errMsg = '请输入人员手机号'
      } else if (!idNoReg.test(this.inputTouristIdNo)) {
        errMsg = '请填写正确的证件号码'
      } else if (!mobileReg.test(this.inputTouristMobile)) {
        errMsg = '请填写正确的手机号码'
      }
      if (errMsg) {
        ElMessage({
          message: errMsg,
          offset: this.offset,
          type: 'error'
        })
        return
      }
      const tourist = {
        name: this.inputTouristName,
        idType: this.inputTouristIdType.id,
        idNo: this.inputTouristIdNo,
        mobile: this.inputTouristMobile
      }
      if (this.editingTouristId) {
        tourist.id = this.editingTouristId
        tourist.uuid = this.editingTouristUuid
        this.doUpdateTourist(tourist)
      } else {
        this.doCreateTourist(tourist)
      }
    },
    doCreateTourist (tourist) {
      addTourist(tourist).then(res => {
        const data = res.data
        console.log(data)
        this.touristList.push(this.convertTourist(data))
        const msg = '添加人员‘' + tourist.name + '’成功'
        this.$confirm(msg, '添加成功', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'success'
        }).then(() => {
          this.touristAddVisible = false
        }).catch((err) => {
          console.log(err)
        })
      }, res => {
        console.log(res)
        ElMessage({
          message: res,
          offset: this.offset,
          type: 'error'
        })
      })
    },
    doUpdateTourist (tourist) {
      updateTourist(tourist).then(res => {
        const data = res.data
        console.log(data)
        for (var i = 0; i < this.touristList.length; i++) {
          const item = this.touristList[i]
          if (item.id === tourist.id) {
            this.touristList[i] = this.convertTourist(tourist)
            break
          }
        }
        const msg = '修改人员‘' + tourist.name + '’信息成功'
        this.$confirm(msg, '修改成功', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'success'
        }).then(() => {
          this.touristAddVisible = false
        }).catch((err) => {
          console.log(err)
        })
      }, res => {
        console.log(res)
        ElMessage({
          message: res,
          offset: this.offset,
          type: 'error'
        })
      })
    },
    doDeleteTourist (tourist) {
      deleteTourist(tourist).then(res => {
        const data = res.data
        console.log(data)
        for (var i = 0; i < this.touristList.length; i++) {
          if (tourist.id === this.touristList[i].id) {
            this.touristList.splice(i, 1)
            break
          }
        }
        const msg = '删除人员‘' + tourist.name + '’成功'
        this.$confirm(msg, '删除成功', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'success'
        }).then(() => {
          this.touristAddVisible = false
        }).catch((err) => {
          console.log(err)
        })
      }, res => {
        console.log(res)
        ElMessage({
          message: res,
          offset: this.offset,
          type: 'error'
        })
      })
    },
    makeReservation () {
      const offset = 300
      if (!this.noticeRead) {
        ElMessage({
          message: '请先阅读预约须知',
          offset: this.offset,
          type: 'error'
        })
        return
      }
      if (!this.checkedTouristList || this.checkedTouristList.length <= 0) {
        if (!this.touristList || this.touristList.length <= 0) {
          ElMessage({
            message: '请先添加人员',
            offset: this.offset,
            type: 'error'
          })
        } else {
          ElMessage({
            message: '请先选择人员',
            offset: this.offset,
            type: 'error'
          })
        }
        return
      }
      const reservationData = {
        siteId: this.siteId,
        reservationDate: this.pickResult.date.fullDate,
        slotId: this.pickResult.slot.id,
        touristIds: this.checkedTouristList.map(item => item.id)
      }
      makeReservation(reservationData).then(res => {
        const data = res.data
        console.log(data)
        const msg = this.pickResult.date.fullDate + this.pickResult.slot.name +
          '：预约成功！（人员' + this.checkedTouristList.length + '人）'
        this.$confirm(msg, '预约成功', {
          confirmButtonText: '查看预约记录',
          showCancelButton: false,
          type: 'success'
        }).then(() => {
          this.$router.replace('/mine?site=' + this.siteId)
        }).catch((err) => {
          console.log(err)
        })
      }, res => {
        console.log(res)
        ElMessage({
          message: res,
          offset: this.offset,
          type: 'error'
        })
      })
    },
    goToMyReservation () {
      this.$router.push('/mine?site=' + this.siteId)
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('site id is:' + query.site)
    const siteId = query.site ? query.site : 2
    this.siteId = siteId
    this.generateDateList()
    this.getTouristList()
    this.generateNoticeList()
  }
}
</script>
