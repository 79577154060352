import request from '../utils/request'

export const reservationDetail = id => {
  return request({
    url: '/reservation/' + id,
    method: 'get'
  })
}

export const reservationList = (siteId, page, pageSize) => {
  return request({
    url: '/reservation/list',
    method: 'get',
    params: {
      site: siteId,
      page: page,
      pageSize: pageSize
    }
  })
}

export const makeReservation = data => {
  return request({
    url: '/reservation/make',
    method: 'post',
    data: data
  })
}

export const cancelReservation = data => {
  return request({
    url: '/reservation/cancel',
    method: 'post',
    data: data
  })
}
