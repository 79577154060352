import request from '../utils/request'

export const siteDetail = id => {
  return request({
    url: '/site/' + id,
    method: 'get'
  })
}

export const siteList = (page, pageSize) => {
  return request({
    url: '/site/list',
    method: 'post',
    data: {
      page: page,
      pageSize: pageSize
    }
  })
}

export const slotList = (site, date) => {
  return request({
    url: '/reservationSlot/list',
    method: 'get',
    params: {
      site: site,
      date: date
    }
  })
}

export const siteUnauthDetail = id => {
  return request({
    url: '/site/unauth/' + id,
    method: 'get'
  })
}

export const siteUnauthList = (page, pageSize) => {
  return request({
    url: '/site/unauth/list',
    method: 'post',
    data: {
      page: page,
      pageSize: pageSize
    }
  })
}
