<template>
  <div class="admin-container">
    <div v-if="adminLevel > 0" class="admin-info">
      <span>{{adminLevel > 1 ? "超级管理员" : "管理员"}}：{{adminName}}</span>
    </div>
    <div v-if="adminLevel > 0" class="reserve-date-picker">
      <div class="pick-result" @click="pickDate">
        <span class="result-header"><span class="emphasis-text">{{selectDate}}</span>已预约<span class="emphasis-text">{{reserveCount}}</span>人</span>
        <span class="result-dropdown">选择日期</span>
        <img class="drop-down-icon" src="../../assets/images/drop-down-gray.png" />
      </div>
      <div class="time-slot-list">
        <div class="slot-list-item" v-for="(item, i) in slotList" :key="i" @click="slotSelect(item)">
          <div :class="slotListItemClass(item)">
            <div class="slot-main">
              <span class="slot-name">{{item.name}}</span>
              <span v-if="item.quota > 0">剩余<span class="slot-quota">{{item.quota}}</span>（已预约{{item.quotaUsed}}人）</span>
              <span v-else>停止预约（已预约{{item.quotaUsed}}人）</span>
            </div>
            <div class="slot-time">
              <span>开放时间：{{item.startTime}} - {{item.endTime}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="adminLevel > 0" class="export-panel">
      <div class="confirm-button" @click="exportReserveList">导出<span class="emphasis-text">{{selectDate}}</span>预约名单</div>
    </div>
    <div v-if="isDevAdmin" class="export-panel">
      <div class="confirm-button" @click="loginAsOtherUser">登录其他账号</div>
      <div class="login-panel-mask" v-if="showOtherUserInput" @click="this.showOtherUserInput=false">
        <div class="login-panel" @click.stop="loginPanelClick">
          <img class="close" @click="this.showOtherUserInput=false" src="../../assets/images/close_round.png" />
          <div class="login-form">
            <div class="form-item">
              <div class="form-title">用户id</div>
              <div class="input-text">
                <el-input placeholder="请输入要登录的用户id" v-model="otherUserId"></el-input>
              </div>
            </div>
            <div class="sign-up-button" @click="doLoginAsOther">登录</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="adminLevel > 0" class="block-button-container">
      <div class="block-button-row">
        <div v-if="adminLevel > 1" class="block-button" @click="siteManagerAuth">
          <img src="../../assets/images/authorization.png" />
          管理员授权
        </div>
        <div class="block-button" @click="reservationVerify">
          <img src="../../assets/images/verification.png" />
          预约码核销
        </div>
      </div>
    </div>
    <div class="bottom-fixed-panel" @click="goBackHome">
      <div class="confirm-button">返回首页</div>
    </div>
    <BottomUpFloatLayer
      :visible="datePickVisible"
      :handleClose="handleDatePickClose"
      title="选择日期"
    >
      <template #contents>
        <div class="date-pick-layer">
          <div class="date-pick-container">
            <div class="year-list">
              <div class="year-list-item" v-for="(item, i) in yearList" :key="i" @click="yearSelect(i)">
                <div :class="yearListItemClass(item)">
                  <span>{{item}}</span>
                </div>
              </div>
            </div>
            <div class="month-list">
              <div class="month-list-item" v-for="(item, i) in monthList" :key="i" @click="monthSelect(i)">
                <div :class="monthListItemClass(item)">
                  <span>{{item}}月</span>
                </div>
              </div>
            </div>
            <div class="date-grid-list">
              <div class="date-grid-item" v-for="i in dateList[monthIdx]" :key="i" @click="dateGridSelect(i)">
                <div :class="dateListItemClass(i)">
                  {{i}}
                </div>
              </div>
            </div>
          </div>
          <div class="button-container">
            <div class="confirm-button" @click="confirmDateSelect">确定</div>
          </div>
        </div>
      </template>
    </BottomUpFloatLayer>

    <div v-if="adminLevel === 0" class="un-authorized-container">
      <img src="../../assets/images/un-authorized.png" />
      <div class="emphasis-text">无权限查看管理页面</div>
      <div>请先点击下方按钮申请成为管理员之后，<br/>再联系负责人授权</div>
    </div>
    <div v-if="adminLevel === 0" class="bottom-button-bar">
      <div class="button-container">
        <div class="confirm-button" @click="applySiteManager">申请成为管理员</div>
      </div>
    </div>
  </div>
</template>

<style>
.qr-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>

<script>
import { ElMessage } from 'element-plus'
import { checkAdmin, reservationExport, loginAsOther } from '../../api/Admin'
import { slotList } from '../../api/Site'
import wx from 'weixin-js-sdk'
import BottomUpFloatLayer from '../../components/common/BottomUpFloatLayer'
import '../../components/ReserveDatePicker.css'
import './Admin.css'

export default {
  data () {
    return {
      siteId: 0,
      adminLevel: 0,
      reserveCount: 0,
      datePickVisible: false,
      selectDate: '2022-01-25',
      yearIdx: 0,
      monthIdx: 0,
      date: 0,
      yearList: [],
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      dateList: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      slotList: [],
      isDevAdmin: false,
      showOtherUserInput: false,
      otherUserId: ''
    }
  },
  components: {
    BottomUpFloatLayer
  },
  computed: {
    adminName () {
      const userName = localStorage.getItem('name')
      return userName
    }
  },
  methods: {
    checkAdmin () {
      checkAdmin(this.siteId).then(res => {
        const data = res.data
        console.log(data)
        this.adminLevel = data
        this.initData()
      }, res => {
        console.log(res)
        this.adminLevel = 0
      })
    },
    generateCanlendar () {
      const now = new Date()
      const hour = now.getHours()
      var defaultDate = now
      const oneDayMils = 1000 * 3600 * 24
      if (hour >= 12) {
        defaultDate = new Date(now.getTime() + oneDayMils)
      }
      this.yearList = []
      const year = defaultDate.getFullYear()
      const yearBuffer = 2
      for (var i = year - yearBuffer; i < year + yearBuffer; i++) {
        this.yearList.push(i)
      }
      this.yearIdx = yearBuffer
      this.monthIdx = defaultDate.getMonth()
      this.date = defaultDate.getDate()

      this.dateSelect()
    },
    yearSelect (idx) {
      this.yearIdx = idx
    },
    monthSelect (idx) {
      this.monthIdx = idx
    },
    dateGridSelect (idx) {
      this.date = idx
    },
    dateSelect () {
      var year = 2022
      if (this.yearList.length > this.yearIdx) {
        year = this.yearList[this.yearIdx]
      }
      var month = 1
      if (this.monthList.length > this.monthIdx) {
        month = this.monthList[this.monthIdx]
      }
      var date = this.date
      this.selectDate = year + '-' + month + '-' + date
      this.getSlotList(this.selectDate)
    },
    confirmDateSelect () {
      this.dateSelect()
      this.datePickVisible = false
    },
    getSlotList (date) {
      console.log('site id:' + this.siteId)
      slotList(this.siteId, date).then(res => {
        const data = res.data
        console.log(data)
        this.reserveCount = 0
        this.slotList = data.list.map(item => {
          this.reserveCount = this.reserveCount + item.quotaUsed
          return {
            id: item.slot.id,
            name: item.slot.name,
            startTime: this.convertTime(item.slot.startTime),
            endTime: this.convertTime(item.slot.endTime),
            quotaUsed: item.quotaUsed,
            quota: item.quotaLeft
          }
        })
      }, res => {
        console.log(res)
      })
    },
    slotListItemClass (item) {
      const disableClass = item.quota > 0 ? ' ' : ' slot-disable'
      return 'slot-list-item-select admin' + disableClass
    },
    yearListItemClass (item) {
      if (this.yearList[this.yearIdx] === item) {
        return 'year-list-item-select'
      } else {
        return ''
      }
    },
    monthListItemClass (item) {
      if (this.monthList[this.monthIdx] === item) {
        return 'month-list-item-select'
      } else {
        return ''
      }
    },
    dateListItemClass (item) {
      if (this.date === item) {
        return 'date-grid-item-select'
      } else {
        return 'date-grid-item-unselect'
      }
    },
    handleDatePickClose () {
      this.datePickVisible = false
    },
    convertTime (time) {
      const baseHour = 8 // GMT+8
      const tmpDate = new Date(2022, 1, 22, baseHour, 0, 0)
      const ms = tmpDate.getTime()
      const newMs = ms + time
      tmpDate.setTime(newMs)
      var hour = tmpDate.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      var minutes = tmpDate.getMinutes()
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      return hour + ':' + minutes
    },
    pickDate () {
      this.datePickVisible = true
    },
    initData () {
      const userName = localStorage.getItem('name')
      const userId = localStorage.getItem('userId')
      if (userId === '53' && userName === '张乐') {
        this.isDevAdmin = true
      } else {
        this.isDevAdmin = false
      }
      this.generateCanlendar()
    },
    slotSelect (slot) {
      this.$router.push('/admin/reserveList?site=' + this.siteId + '&slot=' + slot.id + '&slotName=' + slot.name + '&date=' + this.selectDate)
    },
    siteManagerAuth () {
      this.$router.push('/admin/siteManagerAuth?site=' + this.siteId)
    },
    reservationVerify () {
      // this.$router.push('/admin/reserveVerify?site=' + this.siteId)
      const token = localStorage.getItem('token')
      // const state = 'userToken:' + token + ',' + 'siteId:' + this.siteId
      const state = {
        userToken: token,
        siteId: this.siteId
      }
      const stateParam = encodeURIComponent(JSON.stringify(state))
      window.location.href = 'https://user.pecaca.com/wxjs/index.html?appId=1&apiId=1&state=' + stateParam
    },
    getTotalReserveCount () {
      var res = 0
      this.slotList.forEach(slot => {
        if (slot.quotaUsed) {
          res += slot.quotaUsed
        }
      })
      return res
    },
    exportReserveList () {
      const count = this.getTotalReserveCount()
      if (!count) {
        this.$alert('当前选择日期‘' + this.selectDate + '’的预约人数为0，无需导出。', '无需导出', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      this.$confirm('导出比较耗时，确定要导出‘' + this.selectDate + '’的预约名单吗?', '导出确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doExport()
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: 'Delete canceled'
        // })
      })
    },
    doExport () {
      console.log('doExport...')
      if (this.is_weixin()) {
        const token = localStorage.getItem('token')
        var url = 'https://book.pecaca.com/api/reservation/admin/export'
        url += '?site=' + this.siteId + '&date=' + this.selectDate + '&token=' + token
        window.open(url)
      } else {
        reservationExport(this.siteId, this.selectDate).then((res) => {
          console.log(res)
          const url = URL.createObjectURL(res)
          const a = document.createElement('a')
          a.download = '预约名单_' + this.selectDate + '.xls'
          a.href = url
          a.click()
          window.URL.revokeObjectURL(url) // 释放该 url
        }).catch((err) => {
          console.log(err)
        })
      }
      console.log('doExport done!')
    },
    loginAsOtherUser () {
      this.showOtherUserInput = true
    },
    doLoginAsOther () {
      if (!this.otherUserId) {
        return
      }
      loginAsOther(this.siteId, this.otherUserId).then(res => {
        const data = res.data
        console.log(data)
        const userToken = data.token.token
        var userName = data.user.nickName ? data.user.nickName : data.user.realName
        var userId = data.user.id
        const userHead = data.user.avatar
        if (!userName) {
          userName = '匿名用户'
        }
        localStorage.setItem('token', userToken)
        localStorage.setItem('name', userName)
        localStorage.setItem('head', userHead)
        localStorage.setItem('userId', userId)
        this.$router.replace('/mine')
      })
    },
    is_weixin () {
      if (this.checkUa('MicroMessenger')) {
        return true
      } else if (this.checkUa('wechat')) {
        return true
      } else if (this.checkUa('weixin')) {
        return true
      } else {
        return false
      }
    },
    checkUa (key) {
      var ua = navigator.userAgent.toLowerCase()
      var res = ua.match(key.toLowerCase())
      if (res === key.toLowerCase()) {
        return true
      } else if (res && res.length && res[0] === key.toLowerCase()) {
        return true
      } else {
        return false
      }
    },
    applySiteManager () {
      this.$router.push('/admin/apply?site=' + this.siteId)
    },
    goLastPage () {
      this.$router.go(-1)
    },
    goBackHome () {
      this.$router.replace('/')
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('site id is:' + query.site)
    const siteId = query.id ? query.site : 2
    this.siteId = siteId
    document.title = '预约管理'
    this.checkAdmin()
  }
}
</script>
