<template>
  <div class="qr-container">
    <h1>{{message}}</h1>
  </div>
</template>

<style>
</style>

<script>
import { loginByOneTimeToken } from '../api/User'
export default {
  data () {
    return {
      message: '登录中，请稍后...'
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    login (token) {
      loginByOneTimeToken(token).then(res => {
        const data = res.data
        console.log(data)
        const userToken = data.token.token
        const userName = data.user.nickName
        const userHead = data.user.avatar
        localStorage.setItem('token', userToken)
        localStorage.setItem('name', userName)
        localStorage.setItem('head', userHead)
        this.$router.replace('/mine')
      }, res => {
        this.message = res
      })
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('token is:' + query.token)
    if (query.token) {
      this.login(query.token)
    } else {
      window.location.href = 'https://user.pecaca.com/api/login/by_wxcallback?appId=1'
    }
  }
}
</script>
