<template>
  <div class="search-box">
    <div class="input-container">
      <el-input
        class="search-input"
        :placeholder="placeholder"
        v-model="keyword">
      </el-input>
      <div class="search-icon" @click="search" />
    </div>
  </div>
</template>

<style>
.search-box {
  width: 100%;
  height: 60px;
  background: #fec34b;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.3);
}
.input-container {
  display: flex;
  align-items: center;
}
.search-input input.el-input__inner {
  width: 100%;
  margin: 10px;
  height: 40px;
  border: 0px;
  border-radius: 20px;
  outline: none;
  padding: 0px 10px;
  font-size: 20px;
  color: #777;
}
.search-icon, .search-icon:active {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/images/search.png");
}
.search-icon:active {
  background-image: url("../assets/images/search2.png");
}
</style>

<script>
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
export default {
  data () {
    return {
      placeholder: '请输入景点名称或关键字',
      keyword: ''
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    keywordInput (e) {
      this.keyword = e.target.value
    },
    search () {
      console.log('search')
      if (!this.keyword) {
        ElMessage({
          message: this.placeholder,
          type: 'error'
        })
        return
      }
      console.log(this.keyword)
      ElMessage({
        message: this.keyword,
        type: 'success'
      })
    }
  }
}
</script>
