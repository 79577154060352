import request from '../utils/request'

export const loginByPassword = data => {
  return request({
    url: '/login/by_password',
    method: 'post',
    data: data
  })
}

export const loginByOneTimeToken = token => {
  return request({
    url: '/login/by_onetimetoken',
    method: 'get',
    params: {
      token: token
    }
  })
}

export const loginByUserToken = token => {
  return request({
    url: '/login/by_usertoken',
    method: 'get',
    params: {
      token: token
    }
  })
}
