<template>
  <div class="site-detail">
    <div class="wait-spin" v-if="!siteIsReady">加载中...</div>
    <SiteInfo v-if="siteIsReady" :site="site" />
    <ReserveDatePicker v-if="siteIsReady" :site="site" />
  </div>
</template>

<style>
.site-detail {
  width: 100%;
  height: 100%;
  background: #eeeeee;
}
.wait-spin {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  color: #ccc;
}
</style>

<script>
import SiteInfo from '../components/SiteInfo'
import ReserveDatePicker from '../components/ReserveDatePicker'
import { siteDetail } from '../api/Site'

export default {
  data () {
    return {
      site: {
        name: '',
        time: '',
        notice: ''
      },
      siteIsReady: false
    }
  },
  components: {
    SiteInfo,
    ReserveDatePicker
  },
  computed: {
  },
  methods: {
    loadSiteInfo (siteId) {
      siteDetail(siteId).then(res => {
        const data = res.data
        if (!data.maxCountPerReserve) {
          data.maxCountPerReserve = 1
        }
        this.site = data
        this.site.time = this.convertTime(data.openTime) + '-' + this.convertTime(data.closeTime)
        this.siteIsReady = true
        document.title = this.site.name + '预约'
      }, res => {
        console.log(res)
      })
    },
    convertTime (time) {
      const baseHour = 8 // GMT+8
      const tmpDate = new Date(2022, 1, 22, baseHour, 0, 0)
      const ms = tmpDate.getTime()
      const newMs = ms + time
      tmpDate.setTime(newMs)
      var hour = tmpDate.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      var minutes = tmpDate.getMinutes()
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      return hour + ':' + minutes
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('site id is:' + query.site)
    const siteId = query.site ? query.site : 2
    this.loadSiteInfo(siteId)
  }
}
</script>
