<template>
  <div class="user-info" :style="{ backgroundImage: 'url(' + site.avatar + ')' }">
    <div class="bg-mask" />
    <div class="user-info-container" v-if="userInfo">
      <div class="user-header-container">
        <img class="user-header-img" :src="userInfo.avatar" />
      </div>
      <div class="user-name">{{userInfo.name}}</div>
    </div>
    <div class="user-info-empty" v-else>
      <div class="user-name">未登录</div>
      <div class="button-panel">
        <div class="normal-button" @click="goLogin">点击登录</div>
        <div v-if="isNotWeixin" class="normal-button" @click="goPasswordLogin">账号密码登录</div>
      </div>
    </div>
    <div class="login-panel-mask" v-if="showLogin" @click="maskClick">
      <div class="login-panel" @click.stop="loginPanelClick">
        <img class="close" @click="closeBtnClick" src="../assets/images/close_round.png" />
        <div class="login-form">
          <div class="form-item">
            <div class="form-title">手机号</div>
            <div class="input-text">
              <el-input placeholder="请输入您的注册手机号" v-model="mobile" @focus="mobileInputFocus"></el-input>
              <div class="tooltip" v-if="showMobileTooltip">请输入您的注册手机号</div>
            </div>
          </div>
          <div class="form-item">
            <div class="form-title">密码</div>
            <div class="input-text">
              <el-input placeholder="请输入您的登录密码" v-model="password" type="password" @focus="passwordInputFocus"></el-input>
              <div class="tooltip" v-if="showPasswordTooltip">请输入您的登录密码</div>
            </div>
          </div>
          <div class="input-error">{{ errorMsg }}</div>
          <div class="sign-up-button" @click="doPasswordLogin">登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './UserInfo.css'
import { loginByUserToken, loginByPassword } from '../api/User'

export default {
  props: {
    site: Object
  },
  data () {
    return {
      userInfo: {
        avatar: 'https://bpic.51yuansu.com/pic3/cover/01/69/80/595f67c042c1b_260.jpg',
        name: '张三'
      },
      isNotWeixin: false,
      showLogin: false,
      mobile: '',
      password: '',
      showMobileTooltip: false,
      showPasswordTooltip: false,
      errorMsg: ''
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    is_weixin () {
      if (this.checkUa('MicroMessenger')) {
        return true
      } else if (this.checkUa('wechat')) {
        return true
      } else if (this.checkUa('weixin')) {
        return true
      } else {
        return false
      }
    },
    checkUa (key) {
      var ua = navigator.userAgent.toLowerCase()
      var res = ua.match(key.toLowerCase())
      if (res === key.toLowerCase()) {
        return true
      } else if (res && res.length && res[0] === key.toLowerCase()) {
        return true
      } else {
        return false
      }
    },
    goLogin () {
      this.$router.replace('/login')
    },
    goPasswordLogin () {
      this.showLogin = true
      this.errorMsg = ''
      console.log('this.showLogin is:' + this.showLogin)
    },
    doPasswordLogin () {
      this.errorMsg = ''
      if (!this.mobile) {
        this.showMobileTooltip = true
        return
      }
      if (!this.password) {
        this.showPasswordTooltip = true
        return
      }
      const data = {
        mobileNumber: this.mobile,
        password: this.password
      }
      loginByPassword(data).then(res => {
        this.userInfo = {}
        const data = res.data
        console.log(data)
        const userToken = data.token.token
        var userName = data.user.nickName ? data.user.nickName : data.user.realName
        var userId = data.user.id
        const userHead = data.user.avatar
        if (!userName) {
          userName = '匿名用户'
        }
        localStorage.setItem('token', userToken)
        localStorage.setItem('name', userName)
        localStorage.setItem('head', userHead)
        localStorage.setItem('userId', userId)
        if (userHead) {
          this.userInfo.avatar = userHead
        }
        if (userName) {
          this.userInfo.name = userName
        } else {
          this.userInfo.name = '匿名用户'
        }
        this.showLogin = false
      }, res => {
        this.userInfo = null
        console.error(res)
        this.errorMsg = res
      })
    },
    loginByUserToken (token) {
      loginByUserToken(token).then(res => {
        const data = res.data
        console.log(data)
        const userToken = data.token.token
        var userName = data.user.nickName ? data.user.nickName : data.user.realName
        const userHead = data.user.avatar
        if (!userName) {
          userName = '匿名用户'
        }
        localStorage.setItem('token', userToken)
        localStorage.setItem('name', userName)
        localStorage.setItem('head', userHead)
        if (userHead) {
          this.userInfo.avatar = userHead
        }
        if (userName) {
          this.userInfo.name = userName
        } else {
          this.userInfo.name = '匿名用户'
        }
      }, res => {
        this.userInfo = null
      })
    },
    mobileInputFocus () {
      this.showMobileTooltip = false
    },
    passwordInputFocus () {
      this.showPasswordTooltip = false
    },
    loginPanelClick () {
      console.log('inputPanelClick')
    },
    closeBtnClick () {
      this.showLogin = false
    },
    maskClick () {
      this.showLogin = false
    }
  },
  mounted () {
    const userToken = localStorage.getItem('token')
    const userName = localStorage.getItem('name')
    const userHead = localStorage.getItem('head')
    if (userHead) {
      this.userInfo.avatar = userHead
    }
    if (userName) {
      this.userInfo.name = userName
    } else if (userToken) {
      this.loginByUserToken(userToken)
    } else {
      this.userInfo = null
    }
    this.isNotWeixin = !this.is_weixin()
  }
}
</script>
