<template>
  <div class="admin-container">
    <div v-if="adminLevel > 0" class="admin-info">
      <span>{{adminLevel > 1 ? "超级管理员" : "管理员"}}：{{adminName}}</span>
    </div>
    <div v-if="adminLevel === 2" class="site-manager-list">
      <div v-for="(item, i) in siteMgrList" :key="i" class="site-manager-item">
        <div class="site-manager-status">
          <div v-if="item.status > 0" class="status-auth">已授权</div>
          <div v-else class="status-unauth">未授权</div>
        </div>
        <div class="site-manager-info">{{item.realName}}（{{item.mobileNumber}}）</div>
        <div class="site-manager-op">
          <div v-if="item.status > 0" class="op-deauth" @click="deAuth(item)">撤销</div>
          <div v-else class="op-auth" @click="auth(item)">授权</div>
          <div class="op-delete" @click="deleteAuth(item)">删除</div>
        </div>
      </div>
      <div v-if="!siteMgrList || siteMgrList.length === 0" class="un-authorized-container">
        <img src="../../assets/images/empty.png" />
        <div class="emphasis-text">没有收到管理员申请</div>
      </div>
    </div>
    <div v-else-if="adminLevel > 0" class="un-authorized-container">
      <img src="../../assets/images/applied.png" />
      <div class="emphasis-text">{{userName}}：申请已提交</div>
      <div>请等待【{{site ? site.name : ''}}】负责人审核</div>
    </div>
    <div v-else class="un-authorized-container">
      <img src="../../assets/images/un-authorized.png" />
      <div class="emphasis-text">无权限查看管理员授权页面</div>
      <div>【{{site ? site.name : ''}}】负责人才能进行管理员授权</div>
    </div>
    <div class="bottom-button-bar">
      <div class="button-container">
        <div class="confirm-button" @click="goLastPage">返回</div>
      </div>
    </div>
  </div>
</template>

<style>
.qr-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>

<script>
import { ElMessage } from 'element-plus'
import { checkAdmin, siteManagerList, siteManagerAuthorize, siteManagerDeAuthorize, siteManagerDelete } from '../../api/Admin'
import { siteDetail } from '../../api/Site'
import '../../components/ReserveDatePicker.css'
import './Admin.css'
import './SiteManagerAuth.css'

export default {
  data () {
    return {
      siteId: 0,
      adminLevel: 0,
      site: null,
      count: 0,
      siteMgrList: []
    }
  },
  components: {
  },
  computed: {
    adminName () {
      const userName = localStorage.getItem('name')
      return userName
    }
  },
  methods: {
    checkAdmin () {
      checkAdmin(this.siteId).then(res => {
        const data = res.data
        console.log(data)
        this.adminLevel = data
        this.initData()
      }, res => {
        console.log(res)
        this.adminLevel = 0
      })
    },
    removeItem (list, item) {
      if (!item) {
        return
      }
      for (var i = 0; i < list.length; i++) {
        if (list[i].id === item.id) {
          list.splice(i, 1)
        }
      }
    },
    initData () {
      this.getSiteDetail()
      this.listSiteManagers()
    },
    getSiteDetail () {
      siteDetail(this.siteId).then(res => {
        const data = res.data
        this.site = data
      }, res => {
        console.log(res)
      })
    },
    listSiteManagers () {
      siteManagerList(this.siteId, 1, 20).then(res => {
        const data = res.data
        console.log(data)
        this.count = data.count
        this.siteMgrList = data.list
      }, res => {
        console.log(res)
      })
    },
    auth (item) {
      const msg = '确定授权"' + item.realName + '"为【' + this.site.name + '】的管理员吗？'
      this.$confirm(msg, '授权确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doAuth(item)
      }).catch((err) => {
        console.log(err)
      })
    },
    deAuth (item) {
      const msg = '确定撤销授权"' + item.realName + '"【' + this.site.name + '】的管理员授权吗？'
      this.$confirm(msg, '撤销授权确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doDeAuth(item)
      }).catch((err) => {
        console.log(err)
      })
    },
    deleteAuth (item) {
      const msg = '确定删除"' + item.realName + '"的管理员信息吗？'
      this.$confirm(msg, '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doDelete(item)
      }).catch((err) => {
        console.log(err)
      })
    },
    doAuth (item) {
      siteManagerAuthorize(item).then(res => {
        this.$confirm('授权成功', '授权成功', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'success'
        })
        item.status = 1
      }, res => {
        console.log(res)
        this.$confirm('授权失败，请稍后重试', '授权失败', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'error'
        })
      })
    },
    doDeAuth (item) {
      siteManagerDeAuthorize(item).then(res => {
        this.$confirm('撤销授权成功', '撤销授权成功', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'success'
        })
        item.status = 0
      }, res => {
        console.log(res)
        this.$confirm('撤销授权失败，请稍后重试', '撤销授权失败', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'error'
        })
      })
    },
    doDelete (item) {
      siteManagerDelete(item).then(res => {
        this.$confirm('删除成功', '删除成功', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'success'
        })
        this.removeItem(this.siteMgrList, item)
      }, res => {
        console.log(res)
        this.$confirm('删除管理员失败，请稍后重试', '删除失败', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'error'
        })
      })
    },
    goLastPage () {
      this.$router.go(-1)
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('site id is:' + query.id)
    const siteId = query.id ? query.id : 1
    this.siteId = siteId
    document.title = '预约管理'
    this.checkAdmin()
  }
}
</script>
