<template>
  <div class="admin-container">
    <div v-if="adminLevel > 0" class="un-authorized-container">
      <img src="../../assets/images/authorized.png" />
      <div class="emphasis-text">{{userName}}：您已经是管理员了</div>
      <div>无需重复申请</div>
    </div>
    <div v-else-if="applyDetail" class="un-authorized-container">
      <img src="../../assets/images/applied.png" />
      <div class="emphasis-text">{{userName}}：申请已提交</div>
      <div>请等待【{{site ? site.name : ''}}】负责人审核</div>
    </div>
    <div v-if="adminLevel > 0 || applyDetail" class="bottom-button-bar">
      <div class="button-container">
        <div class="confirm-button" @click="goLastPage">返回</div>
      </div>
    </div>

    <div v-if="adminLevel === 0 && !applyDetail" class="un-authorized-container">
      <div class="emphasis-text">{{userName}}：申请成为【{{site ? site.name : ''}}】的管理员</div>
      <div class="text-input">
        <div class="text-input-header">真实姓名</div>
        <div class="text-input-input">
          <el-input placeholder="请输入真实姓名" v-model="inputRealName"></el-input>
          <img class="input-close-icon" v-if="inputRealName"
            src="../../assets/images/close_round.png"
            @click="()=>{inputRealName = ''}" />
        </div>
      </div>
      <div class="text-input">
        <div class="text-input-header">手机号</div>
        <div class="text-input-input">
          <el-input placeholder="请输入手机号" v-model="inputMobile"></el-input>
          <img class="input-close-icon" v-if="inputMobile"
            src="../../assets/images/close_round.png"
            @click="()=>{inputMobile = ''}" />
        </div>
      </div>
    </div>
    <div v-if="adminLevel === 0 && !applyDetail" class="bottom-button-bar">
      <div class="button-container">
        <div class="confirm-button" @click="applySiteManager">提交申请</div>
      </div>
    </div>
  </div>
</template>

<style>
.qr-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>

<script>
import { ElMessage } from 'element-plus'
import { checkAdmin, siteManagerApply, siteManagerApplyDetail } from '../../api/Admin'
import { siteDetail } from '../../api/Site'
import './Admin.css'
import './Apply.css'

export default {
  data () {
    return {
      siteId: 0,
      site: null,
      adminLevel: 0,
      offset: 300,
      inputRealName: '',
      inputMobile: '',
      applyDetail: null
    }
  },
  components: {
  },
  computed: {
    userName () {
      const userName = localStorage.getItem('name')
      return userName
    }
  },
  methods: {
    checkAdmin () {
      checkAdmin(this.siteId).then(res => {
        const data = res.data
        console.log(data)
        this.adminLevel = data
      }, res => {
        console.log(res)
        this.adminLevel = 0
        this.initData()
      })
    },
    initData () {
      this.getSiteDetail()
      this.getApplyDetail()
    },
    getSiteDetail () {
      siteDetail(this.siteId).then(res => {
        const data = res.data
        this.site = data
      }, res => {
        console.log(res)
      })
    },
    getApplyDetail () {
      siteManagerApplyDetail(this.siteId).then(res => {
        const data = res.data
        this.applyDetail = data
      }, res => {
        console.log(res)
      })
    },
    applySiteManager () {
      var errMsg = ''
      const mobileReg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!this.inputRealName) {
        errMsg = '请输入真实姓名'
      } else if (!this.inputMobile) {
        errMsg = '请输入手机号'
      } else if (!mobileReg.test(this.inputMobile)) {
        errMsg = '请填写正确的手机号码'
      }
      if (errMsg) {
        ElMessage({
          message: errMsg,
          offset: this.offset,
          type: 'error'
        })
        return
      }
      this.submitApply()
    },
    submitApply () {
      const applyData = {
        siteId: this.siteId,
        realName: this.inputRealName,
        mobileNumber: this.inputMobile
      }
      siteManagerApply(applyData).then(res => {
        const data = res.data
        console.log(data)
        this.applyDetail = data
      }, res => {
        console.log(res)
      })
    },
    goLastPage () {
      this.$router.go(-1)
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('site id is:' + query.id)
    const siteId = query.id ? query.id : 1
    this.siteId = siteId
    document.title = '管理员申请'
    this.checkAdmin()
  }
}
</script>
