<template>
  <transition name="mask">
    <div class="bottom-up-float-layer" v-if="visible" @click="handleClose">
      <div class="bottom-up-float-layer-mask" v-if="visible" key="mask"></div>
      <div :class="floatLayerClassName" @click="floatLayerClick">
        <div class="float-layer-header">
          <span>{{title}}</span>
          <img class="close" @click="closeBtnClick" src="../../assets/images/close.png" />
        </div>
        <div class="float-layer-contents">
          <slot name="contents" />
        </div>
      </div>
    </div>
  </transition>
</template>

<style>
.bottom-up-float-layer-mask {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0,0,0,0.5);
}
.float-layer {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  animation: float-up .5s;
  -webkit-animation: float-up .5s;
}
.float-layer-hidden {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  animation: float-down .5s;
  -webkit-animation: float-down .5s;
}
.float-layer-header {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #DEDEDE;
  border-radius: 20px 20px 0px 0px;
  font-weight: bold;
  font-size: 18px;
}
.close {
  width: 25px;
  height: 25px;
}
.float-layer-contents {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 200px;
  background: #FFFFFF;
}
/*过渡动画*/
.mask-enter-active{
  transition: opacity .5s;
}
.mask-enter {
  opacity: 0;
}
.mask-leave-active{
  transition: opacity .5s;
}
.mask-leave-to{
  opacity: 0;
}

/*自定义动画-浮层上浮*/
@keyframes float-up {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@-webkit-keyframes float-up {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
/*自定义动画-浮层下潜*/
@keyframes float-down {
    0% {
        opacity: 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}
@-webkit-keyframes float-down {
    0% {
        opacity: 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}
</style>

<script>
export default {
  props: {
    title: String,
    visible: Boolean,
    handleClose: Function
  },
  data () {
    return {
    }
  },
  components: {
  },
  computed: {
    floatLayerClassName () {
      if (this.visible) {
        return 'float-layer'
      } else {
        return 'float-layer-hidden'
      }
    }
  },
  methods: {
    floatLayerClick (event) {
      event.stopPropagation()
    },
    closeBtnClick (event) {
      event.stopPropagation()
      this.handleClose()
    }
  }
}
</script>
