<template>
  <div class="digit-clock">
    <span>{{timeStr}}</span>
  </div>
</template>

<style>
.digit-clock {
  width: 100%;
  height: 60px;
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
</style>

<script>
export default {
  data () {
    return {
      timeStr: ''
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    getDateTimeStr () {
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      month = month < 10 ? '0' + month : month
      var dates = date.getDate()
      dates = dates < 10 ? '0' + dates : dates
      var arr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      var day = date.getDay()
      var hour = date.getHours()
      // var half = hour >= 12 ? '下午' : '上午'
      // hour = hour <= 12 ? hour : (hour - 12)
      // hour = hour < 10 ? '0' + hour : hour
      var min = date.getMinutes()
      min = min < 10 ? '0' + min : min
      var sed = date.getSeconds()
      sed = sed < 10 ? '0' + sed : sed
      return (year + '-' + month + '-' + dates + ' ' + ' ' + hour + ':' + min + ':' + sed)
    }
  },
  mounted () {
    this.timeStr = this.getDateTimeStr()
    setInterval(() => { this.timeStr = this.getDateTimeStr() }, 1000)
  }
}
</script>
