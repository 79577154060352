import axios from 'axios'

function createAxiosInstance () {
  var axiosBaseURL = ''
  // 运行环境判断
  console.log('env is: ' + process.env.NODE_ENV)
  if (process.env.NODE_ENV === 'production') {
    /* 生产环境 */
    axiosBaseURL = 'https://book.pecaca.com/api' // 接口根路径
  } else if (process.env.NODE_ENV === 'test') {
    /* 测试环境 */
    axiosBaseURL = 'https://book.pecaca.com/api' // 接口根路径
  } else {
    /* 开发环境 */
    axiosBaseURL = 'https://book.pecaca.com/api' // 接口根路径
  }

  console.log(axiosBaseURL)
  const token = localStorage.getItem('token')
  console.log('request, token is:' + token)
  return axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    baseURL: axiosBaseURL,
    timeout: 60000,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  })
}

function goLogin () {
  if (!window.location.href.endsWith('/mine')) {
    window.location.href = '/mine'
  }
}
const service = createAxiosInstance()

service.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      config.data = JSON.stringify(config.data)
    }
    const token = localStorage.getItem('token')
    config.headers.Authorization = token
    return config
  },
  error => {
    console.err(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return response.data
    } else if (response.status === 401) {
      goLogin()
    } else {
      Promise.reject(response.status)
    }
  },
  error => {
    console.error(error)
    if (error.response && error.response.status && error.response.status === 401) {
      goLogin()
    }
    if (error.response && error.response.data && error.response.data.msg) {
      return Promise.reject(error.response.data.msg)
    }
    return Promise.reject(error)
  }
)

export default service
