<template>
  <div class="qr-container">
    <div class="reserve-date-slot">{{qrcode}}</div>
    <div class="bottom-button-bar">
      <div class="button-container">
        <div class="confirm-button" @click="goLastPage">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import './ReserveDetail.css'

export default {
  data () {
    return {
      qrcode: ''
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    goLastPage () {
      this.$router.go(-1)
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('device id is:' + query.qrcode)
    console.log('reserve state is:' + query.state)
    this.qrcode = query.qrcode
    if (query.state) {
      const stateObj = JSON.parse(decodeURIComponent(query.state))
      console.log('site id is:' + stateObj.siteId)
      console.log('user token is:' + stateObj.userToken)
    }
  }
}
</script>
