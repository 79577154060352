<template>
  <div class="mine-container">
    <UserInfo v-if="siteIsReady" :site="site" />
    <ReserveRecord v-if="siteIsReady" :site="site" />
    <div class="button-container">
      <div class="confirm-button" @click="goToReservePage">开始预约</div>
    </div>
  </div>
</template>

<style scoped>
.mine-container {
  width: 100%;
  height: 100%;
  background: #EFEFEF;
}
.button-container {
  position: absolute;
  bottom: 0px;
}
</style>

<script>
import UserInfo from '../components/UserInfo'
import ReserveRecord from '../components/ReserveRecord'
import { siteUnauthDetail } from '../api/Site'
export default {
  data () {
    return {
      site: null,
      siteIsReady: false
    }
  },
  components: {
    UserInfo,
    ReserveRecord
  },
  computed: {
  },
  methods: {
    goToReservePage () {
      const siteId = this.site && this.site.id ? this.site.id : 2
      this.$router.replace('/site?site=' + siteId)
    },
    loadSiteInfo (siteId) {
      siteUnauthDetail(siteId).then(res => {
        const data = res.data
        if (!data.maxCountPerReserve) {
          data.maxCountPerReserve = 1
        }
        this.site = data
        this.siteIsReady = true
        document.title = this.site.name + '预约'
      }, res => {
        console.log(res)
      })
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('site id is:' + query.site)
    const siteId = query.site ? query.site : 2
    this.loadSiteInfo(siteId)
  }
}
</script>
