import request from '../utils/request'

export const touristDetail = uuid => {
  return request({
    url: '/tourist/' + uuid,
    method: 'get'
  })
}

export const touristList = (page, pageSize) => {
  return request({
    url: '/tourist/list',
    method: 'get',
    data: {
      page: page,
      pageSize: pageSize
    }
  })
}

export const addTourist = data => {
  return request({
    url: '/tourist/add',
    method: 'post',
    data: data
  })
}

export const deleteTourist = data => {
  return request({
    url: '/tourist/delete',
    method: 'post',
    data: data
  })
}

export const updateTourist = data => {
  return request({
    url: '/tourist/update',
    method: 'post',
    data: data
  })
}
