<template>
  <div class="qr-container">
    <DigitClock />
    <div class="reserve-date-slot">{{reservationDate}} {{reservationSlotName}}</div>
    <div class="reserve-site-name">【{{siteName}}】</div>
    <div class="reserve-owner-name">【{{ownerName}}】的预约详情</div>
    <ReserveTourist :tourists="tourists" />
    <div class="bottom-button-bar">
      <div class="button-container">
        <div class="confirm-button" @click="goLastPage">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import DigitClock from '../../components/DigitClock'
import ReserveTourist from '../../components/ReserveTourist'
import { reservationDetail } from '../../api/Admin'
import './ReserveDetail.css'

export default {
  data () {
    return {
      reserveId: 0,
      reservation: null,
      tourists: []
    }
  },
  components: {
    DigitClock,
    ReserveTourist
  },
  computed: {
    reservationDate () {
      return this.reservation ? this.reservation.reservationDate : ''
    },
    reservationSlotName () {
      return this.reservation ? this.reservation.reservationSlotName : ''
    },
    siteName () {
      return this.reservation ? this.reservation.siteName : ''
    },
    ownerName () {
      return this.reservation ? this.reservation.ownerName : ''
    }
  },
  methods: {
    getReservationDetail () {
      if (!this.reserveId) {
        return
      }
      reservationDetail(this.reserveId).then(res => {
        const data = res.data
        console.log(data)
        this.reservation = data.reservation
        this.tourists = data.tourists
      }, res => {
        console.log(res)
        ElMessage({
          message: res,
          offset: this.offset,
          type: 'error'
        })
      })
    },
    goLastPage () {
      this.$router.go(-1)
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('reserve id is:' + query.reserve)
    const reserveId = query.reserve ? query.reserve : 0
    this.reserveId = reserveId
    this.getReservationDetail()
  }
}
</script>
