<template>
  <div class="reserve-detail">
    <div class="text-normal">您已经成功预约</div>
    <div class="reserve-date-slot">{{reservationDate}} {{reservationSlotName}}</div>
    <div class="reserve-site-name">【{{siteName}}】</div>
    <div class="text-normal">请当日向工作人员提供预约码购票进入。</div>
    <qrcode-vue :value="qrCodeUrl" :size="size" level="H" foreground="#008000" />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import './ReserveDetail.css'

export default {
  props: {
    reservation: Object
  },
  data () {
    return {
      size: 150
    }
  },
  components: {
    QrcodeVue
  },
  computed: {
    reservationDate () {
      return this.reservation ? this.reservation.reservationDate : ''
    },
    reservationSlotName () {
      return this.reservation ? this.reservation.reservationSlotName : ''
    },
    siteName () {
      return this.reservation ? this.reservation.siteName : ''
    },
    qrCodeUrl () {
      return this.reservation ? this.reservation.uuid : ''
    }
  },
  methods: {
  }
}
</script>
