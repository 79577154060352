import request from '../utils/request'

export const checkAdmin = siteId => {
  return request({
    url: '/reservation/admin/check',
    method: 'get',
    params: {
      site: siteId
    }
  })
}

export const reservationDetail = id => {
  return request({
    url: '/reservation/admin/' + id,
    method: 'get'
  })
}

export const reservationDetailByUuid = uuid => {
  return request({
    url: '/reservation/admin/detail',
    method: 'get',
    params: {
      uuid: uuid
    }
  })
}

export const reservationList = (siteId, slotId, dateStr, page, pageSize) => {
  return request({
    url: '/reservation/admin/list',
    method: 'get',
    params: {
      site: siteId,
      slot: slotId,
      date: dateStr,
      page: page,
      pageSize: pageSize
    }
  })
}

export const reservationExport = (siteId, dateStr) => {
  const token = localStorage.getItem('token')
  return request({
    url: '/reservation/admin/export',
    method: 'get',
    responseType: 'blob',
    params: {
      site: siteId,
      date: dateStr,
      token: token
    }
  })
}

export const siteManagerList = (siteId, page, pageSize) => {
  return request({
    url: '/site/manager/list',
    method: 'get',
    params: {
      site: siteId,
      page: page,
      pageSize: pageSize
    }
  })
}

export const siteManagerApplyDetail = siteId => {
  return request({
    url: '/site/manager/apply/detail',
    method: 'get',
    params: {
      site: siteId
    }
  })
}

export const siteManagerApply = data => {
  return request({
    url: '/site/manager/apply',
    method: 'post',
    data: data
  })
}

export const siteManagerAuthorize = data => {
  return request({
    url: '/site/manager/authorize',
    method: 'post',
    data: data
  })
}

export const siteManagerDeAuthorize = data => {
  return request({
    url: '/site/manager/deAuthorize',
    method: 'post',
    data: data
  })
}

export const siteManagerDelete = data => {
  return request({
    url: '/site/manager/delete',
    method: 'post',
    data: data
  })
}

export const loginAsOther = (siteId, userId) => {
  return request({
    url: '/reservation/admin/loginAsOther',
    method: 'post',
    data: {},
    params: {
      site: siteId,
      userId: userId
    }
  })
}
