<template>
  <div class="reserve-record-container">
    <div class="reserve-record-list">
      <div class="reserve-record-list-header">我的预约记录</div>
      <div class="reserve-record-empty" v-if="loading">加载中，请稍候...</div>
      <div class="reserve-record-empty" v-else-if="records.length===0">未找到您的预约记录~</div>
      <div class="reserve-record-item" v-for="(item, i) in records" :key="i">
        <div>{{item.reservationDate}} {{item.reservationSlotName}}</div>
        <div>{{item.site.name}}</div>
        <div>{{item.touristCount}}人</div>
        <div class="reserve-qr-icon" @click="gotoReserveDetail(item)">预约码</div>
      </div>
    </div>
  </div>
</template>

<script>
import './ReserveRecord.css'
import { reservationList } from '../api/Reservation'

export default {
  props: {
    site: Object
  },
  data () {
    return {
      retryTimes: 0,
      loading: false,
      records: []
    }
  },
  components: {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    listMyRecords () {
      this.loading = true
      const siteId = this.site && this.site.id ? this.site.id : 2
      reservationList(siteId, 1, 10).then(res => {
        const data = res.data
        console.log(data)
        this.records = data.list.map(item => {
          return this.convertReservation(item)
        })
        this.loading = false
      }, res => {
        console.log(res)
        this.loading = false
      })
    },
    convertReservation (item) {
      return {
        id: item.id,
        site: {
          id: item.siteId,
          name: item.siteName
        },
        reservationDate: item.reservationDate,
        reservationSlotName: item.reservationSlotName,
        touristCount: item.touristCount
      }
    },
    gotoReserveDetail (item) {
      this.$router.push('/qr?reserve=' + item.id)
    }
  },
  mounted () {
    const that = this
    this.loading = true
    const ovTime = setInterval(function () {
      const token = localStorage.getItem('token')
      console.log('token is:' + token)
      that.retryTimes++
      if (token || that.retryTimes > 20) {
        clearInterval(ovTime)
        that.listMyRecords()
      }
    }, 500)
  }
}
</script>
