<template>
  <div class="invite-container">
    <div class="sign-up-container" v-if="signUpDone">
      <!-- <canvas id="inviteCanvas" width="100%" height="100%"></canvas> -->
      <img class="invite-bg" src="https://static2.xskey.cn/talents/club/invite2.jpg" />
      <div class="name-panel" :style="nameStyle">{{ name }}</div>
    </div>
    <div class="sign-up-container" v-else>
      <img class="invite-bg" src="https://static2.xskey.cn/talents/club/invite2.jpg" />
      <div class="button" @click="signUpNow">立即报名</div>
    </div>
    <div class="input-panel-mask" v-if="showInput" @click="maskClick">
      <div class="input-panel" @click.stop="inputPanelClick">
        <img class="close" @click="closeBtnClick" src="../../assets/images/close_round.png" />
        <div v-if="signUpDone">
          <div class="sign-up-success-text">报名成功<br/>请截屏保存邀请函</div>
          <div class="sign-up-button" @click="this.showInput=false">好的</div>
        </div>
        <div v-else>
          <div class="form-title">您的姓名</div>
          <div class="input-text">
            <el-input placeholder="请输入您的姓名" v-model="name" ref="nameInput" @focus="inputFocus"></el-input>
            <div class="tooltip" v-if="showTooltip">请输入您的姓名</div>
          </div>
          <div class="input-error">{{ errorMsg }}</div>
          <div class="sign-up-button" @click="submitName">报名</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkName } from '../../api/OverseaStudent'
export default {
  data () {
    return {
      showInput: false,
      name: '',
      showTooltip: false,
      signUpDone: false,
      nameStyle: { top: '580px' },
      errorMsg: ''
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    signUpNow () {
      this.showInput = true
    },
    inputFocus () {
      this.showTooltip = false
    },
    submitName () {
      if (this.name) {
        checkName(this.name).then(res => {
          const data = res.data
          console.log(data)
          this.signUpDone = true
          this.updateNamePos()
        }, res => {
          console.error(res)
          this.errorMsg = res
        })
      } else {
        // this.$refs.nameInput.focus()
        this.showTooltip = true
      }
    },
    updateNamePos () {
      const height = document.documentElement.clientHeight
      const pos = Math.floor(height * 0.436)
      this.nameStyle.top = pos + 'px'
    },
    // drawCanvas () {
    //   const that = this
    //   var canvas = document.getElementById('inviteCanvas')
    //   var a = setInterval(() => {
    //     //  重复获取
    //     canvas = document.getElementById('inviteCanvas')
    //     if (!canvas) {
    //       return false
    //     } else {
    //       clearInterval(a)
    //       //  可以理解为一个画笔，可画路径、矩形、文字、图像
    //       var context = canvas.getContext('2d')
    //       var img = new Image()
    //       img.src = 'https://static2.xskey.cn/talents/club/invite.jpg'
    //       //  加载图片
    //       img.onload = function () {
    //         if (img.complete) {
    //           // 根据图像重新设定了canvas的长宽
    //           // canvas.setAttribute('width', img.width)
    //           // canvas.setAttribute('height', img.height)
    //           canvas.setAttribute('width', window.screen.width)
    //           canvas.setAttribute('height', window.screen.height)
    //           // 绘制图片
    //           context.drawImage(img, 0, 0, window.screen.width, window.screen.height)
    //           // 绘制名字
    //           context.font = '24px serif'
    //           const x = window.screen.width - 24 * that.name.length
    //           context.fillText(that.name, x, 400)
    //         }
    //       }
    //     }
    //   }, 100)
    // },
    inputPanelClick () {
      console.log('inputPanelClick')
    },
    closeBtnClick () {
      this.showInput = false
    },
    maskClick () {
      this.showInput = false
    }
  },
  mounted () {
    // todo:
  }
}
</script>

<style scoped>
.invite-container, .sign-up-container {
  height: 100%;
  width: 100%;
}
.invite-bg {
  display: block;
  height: 100%;
  width: 100%;
}
.input-panel-mask {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.input-panel {
  position: fixed;
  height: 350px;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 0px 60px rgb(223, 150, 5);
  border-radius: 10px;
}
.name-panel {
  position: fixed;
  right: 13px;
  top: 380px;
  font-size: 25px;
  font-weight: bold;
  font-family: STFangsong;
  color: #000;
  letter-spacing: 10px;
}
.form-title {
  margin-top: 80px;
  font-size: 20px;
  font-weight: bold;
}
.sign-up-success-text {
  display: inline-block;
  position: fixed;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
}
.input-error {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  color: #cc0000;
}
.input-text {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  height: 50px;
}
.tooltip {
  position: absolute;
  left: 0px;
  top: 50px;
  display: inline-block;
  margin: 10px;
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #ccc;
  font-size: 13px;
}
.tooltip:before {
  content:'';
  position: absolute;
  left: 50%;
  top: -5px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: #333;
}
.close {
  width: 30px;
  height: 30px;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}
.button, .sign-up-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgb(223, 150, 5);
}
.button:active {
  background-color: rgb(241, 135, 6);
  color: #fff;
}
.sign-up-button {
  background-color: rgb(241, 135, 6);
  color: #fff;
}
.sign-up-button:active {
  background-color: rgb(255, 195, 86);
}
/deep/.el-input__inner {
  height: 50px
}
</style>
